@charset "utf-8";

// Image

// トップページ
img[src*="top/txt-about0.png"]{
	width: 87%;
	max-width: 366px;
}

img[src*="top/txt-about.png"]{
	width: 91%;
	max-width: 383px;
}

img[src*="top/btn-about"]{
	width: 72%;
	max-width: 236px;
	margin-left: 25px;
}

img[src*="top/txt-flow"]{
	width: 68%;
	max-width: 287px;
}

img[src*="top/btn-flow"]{
	width: 32%;
	max-width: 104px;
	margin-left: 39px;
}

img[src*="top/txt-story"]{
	width: 68%;
	max-width: 289px;
}

img[src*="top/btn-story"]{
	width: 76%;
	max-width: 248px;
	margin-left: 25px;
}

// Common

img[src*="common/txt-contact"]{
	width: 85.83%;
	max-width: 316px;
}

img[src*="common/btn-contact"]{
	width: 38%;
	max-width: 123px;
	margin-left: 25px;
}

img[src*="common/txt-news"]{
	width: 95%;
	max-width: 399px;
}

img[src*="common/btn-news1"]{
	width: 31%;
	max-width: 122px;
}

img[src*="common/btn-news2"]{
	width: 35%;
	max-width: 139px;
}

img[src*="common/txt-twitter"]{
	width: 27%;
	max-width: 53px;
}

img[src*="common/txt-facebook"]{
	width: 27%;
	max-width: 53px;
}

img[src*="common/txt-instagram"]{
	width: 52%;
	max-width: 228px;

}
img[src*="common/btn-instagram"]{
	
	max-width: 83px;
	@include media(sp){
		width: 24%;
		margin-left: 25px;
	}
}

// about

img[src*="about/txt-title"]{
	width: 83.83%;
	max-width: 448px;
}
img[src*="about/txt-anc-1"]{
	max-width: 34px;
	@include media(sp){
		width: 8.5%;
	}
}
img[src*="about/txt-anc-2"]{
	max-width: 173px;
	@include media(sp){
		width: 42%;
	}
}
img[src*="about/txt-anc-3"]{
	max-width: 95px;
	@include media(sp){
		width: 24.9%;
	}
}
img[src*="about/txt-1"]{
	width: 81.4%;
	max-width: 343px;
}
img[src*="about/txt-2"]{
	width: 84.4%;
	max-width: 356px;
}
img[src*="about/txt-3"]{
	width: 68.2%;
	max-width: 287px;
}
img[src*="about/txt-kuri"]{
	width: 65.7%;
	max-width: 135px;
}
img[src*="about/txt-hinoki"]{
	width: 78.7%;
	max-width: 162px;
}

// flow


img[src*="flow/txt-title.png"]{
	width: 38%;
	max-width: 187px;
}
img[src*="flow/txt-1.png"]{
	width: 12%;
	max-width: 52px;
}
img[src*="flow/txt-1_2.png"]{
	width: 30.6%;
	max-width: 130px;
}
img[src*="flow/txt-2.png"]{
	width: 14.1%;
	max-width: 61px;
}
img[src*="flow/txt-2_2.png"]{
	width: 28.5%;
	max-width: 121px;
}
img[src*="flow/txt-3.png"]{
	width: 14.2%;
	max-width: 61px;
}
img[src*="flow/txt-3_2.png"]{
	width: 17.4%;
	max-width: 74px;
}
img[src*="flow/txt-4.png"]{
	width: 14.2%;
	max-width: 61px;
}
img[src*="flow/txt-4_2.png"]{
	width: 38%;
	max-width: 161px;
}
img[src*="flow/btn-contact.png"]{
	width: 31.7%;
	max-width: 105px;
	margin-left: 25px;
}

// history

img[src*="history/txt-title"]{
	width: 67%;
	max-width: 506px;
	margin-left: auto;
	margin-right: auto;
}

img[src*="history/txt-anc-1"]{
	max-width: 101px;
	// @include media(sp){
	// 	width: 8.5%;
	// }
}
img[src*="history/txt-anc-2"]{
	max-width: 89px;
	// @include media(sp){
	// 	width: 42%;
	// }
}
img[src*="history/txt-anc-3"]{
	max-width: 164px;
	// @include media(sp){
	// 	width: 24.9%;
	// }
}
img[src*="history/txt-1"]{
	// width: 31%;
	max-width: 288px;
}
img[src*="history/txt-2"]{
	// width: 11%;
	max-width: 94px;
}
img[src*="history/txt-3"]{
	// width: 9%;
	max-width: 71px;
}
img[src*="history/txt-4"]{
	// width: 22%;
	max-width: 186px;
}

// contact

img[src*="contact/txt-title.png"]{
	width: 37.6%;
	max-width: 188px;
}

img[src*="contact/txt-1.png"]{
	max-width: 393px;
}

img[src*="contact/btn-toppage.png"]{
	max-width: 166px;
	margin-left: 30px;
}

img[src*="contact/btn-submit.png"]{
	width: 48%;
	max-width: 155px;
	margin-left: 25px;

}








