@charset "utf-8";

// Common

html {
	height: 100%;
}

body {

}

img {
	width: 100%;
	height: auto;
}

.wrapper{
	position: relative;
	height: 100%;
	width: 100%;
}

.box {
	max-width: 1050px;
	margin-left: auto;
	margin-right: auto;
	padding: 120px 20px;
	&.pfix{
		padding: 0 20px;
	}
	&.pfix2{
		padding-bottom: 30px;
	}
	&.pfix-flow0{
		padding: 95px 20px 95px 20px;
	}
	&.pfix-flow{
		padding: 90px 20px 105px 20px;
	}
	&.pfix-flow2{
		padding: 90px 20px 85px 20px;
	}
	.box-inner {
		max-width: 870px;
		margin-left: auto;
		margin-right: auto;
	}
	@include media(sp){
		padding: 70px 30px;
		&.pfix{
			padding: 0 30px;
		}
		&.pfix2{
			padding-bottom: 30px;
		}
		&.pfix-flow0{
			padding: 95px 30px 95px 30px;
		}
		&.pfix-flow{
			padding: 90px 30px 105px 30px;
		}
		&.pfix-flow2{
			padding: 90px 30px 85px 30px;
		}
	}
}

.box-lnav {
	padding: 55px 20px 65px 20px;
	border-bottom: 1px solid #7f7f7f;
	@include media(sp){
		padding: 40px 28px 44px 28px;
	}
	.box-inner {
		max-width: 870px;
		margin-left: auto;
		margin-right: auto;
	}
}

#nav-top {
	border-top: 1px solid #7f7f7f;
	border-bottom: 1px solid #7f7f7f;

}

.divide {
	border-bottom: 1px solid #000;
}

.nav {
	dl{
		height: 90px;
		max-width: 960px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		dt {
			margin-right: 50px;
			max-width: 182px;
			width: 22%;
		}
		dd {
			@include font(16,17,100);
			font-weight: bold;
			vertical-align: middle;
		}
	}
	@include media(tb){
		dl{
			dt {
				margin-right: 15px;
			}
			dd {
				@include font(13,14,10);
			}
		}
		
	}
	@include media(sp){
		.box {
			padding: 0;
		}
		dl{
			max-width: 100%;
			height: auto;
			display: block;
			align-items: left;
			img {
				margin-left: 0;
				width: 36%;
			}
			dt {
				vertical-align: middle;
				margin-right: 0;
				max-width: 100%;
				padding: 0;
				width: 100%;
				a {
					display: block;
					padding: 30px;
				}
			}
			dd {
				border-top: 1px solid #7f7f7f;
				@include font(12,12,100);
				font-weight: bold;
				vertical-align: middle;
				a {
					display: block;
					padding: 30px;
				}
				
			}
		}
	}

}

.box-image{
	display: flex;
	justify-content: space-between;
	width: 100%;
	&.box420 > div{
		width: 48%;
		max-width: 420px;
	}
	@include media(sp){
		display: block;
		&.box420 > div{
			width: 100%;
			max-width: 100%;
			&:first-child {
				margin-bottom: 25px;
			}
		}
	}
}

.list-image {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -3.3%;
	@include media(sp){
		margin-bottom: -4.7vw;
	}
	li {
		width: 31%;
		max-width: 270px;
		margin-bottom: 3.3%;
		@include media(sp){
			width: 47.6%;
			margin-bottom: 4.7vw;
		}
	}
}

.h-style1 {
	margin-bottom: 45px;
	@include media(sp){
		margin-bottom: 30px;
	}
	&.mbfix {
		margin-bottom: 100px;
		@include media(sp){
			margin-bottom: 75px;
		}
	}

}

.t-style1 {
	@include font(16,40,100);
	font-weight: bold;
	@include media(sp){
		@include font(12,30,100);
	}
	&.white {
		color: #fff;
	}
}

.w-half {
	width: 100%;
	max-width: 420px;
}

.t-style2 {
	@include font(16,40,100);
	font-weight: bold;
	border: 2px solid #000;
	padding: 50px 58px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 20px;
		height: 2px;
		background-color: #000;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		height: 2px;
		background-color: #000;
	}
	@include media(sp){
		@include font(12,30,100);
		border: 1px solid #000;
		padding: 36px 36px;
		&:before {
			top: 15px;
			height: 1px;
		}
		&:after {
			bottom: 15px;
			height: 1px;
		}
	}
}

.box-two_column {
	display: flex;
	justify-content: space-between;
	width: 100%;
	@include media(sp){
		display: block;
	}
}

.box-column {
	width: 48.3%;
	@include media(sp){
		width: 100%;
	}
}

.box-facebok {
	background-image: url(/assets/images/common/bg-facebook.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	height: 420px;
	padding: 30px;
	@include media(sp){
		height: auto;
		padding: 22px;
		margin-bottom: 20px;
	}
}

.edge {
	overflow: hidden;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		right: -1px;
		top: -1px;
		width: 0;
		height: 0;
		border-top: 30px solid #fff;
		border-right: 50px solid #fff;
		border-bottom: 30px solid transparent;
		border-left: 50px solid transparent;
		transform: translate3d(0,-60px,0);
		@include media(sp){
			border-top: 22px solid #fff;
			border-right: 38px solid #fff;
			border-bottom: 22px solid transparent;
			border-left: 38px solid transparent;
			transform: translate3d(0,-45px,0);
		}
	}
	&.small {
		&:after {
			border-top: 22px solid #fff;
			border-right: 37px solid #fff;
			border-bottom: 22px solid transparent;
			border-left: 37px solid transparent;
			transform: translate3d(0,-45px,0);
			@include media(sp){
				border-top: 12px solid #fff;
				border-right: 22px solid #fff;
				border-bottom: 12px solid transparent;
				border-left: 22px solid transparent;
				transform: translate3d(0,-25px,0);
			}
		}
	}
}

// 共通部分 インスタグラム

.list-instagram {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: -30px;
	@include media(sp){
		margin-bottom: 8vw;
	}

	li {
		width: 17.2%;
		background-color: #000;
		margin-bottom: 30px;
		position: relative;
		&.pic-instagram {
			overflow: hidden;
			a {
				display: block;
				position: absolute;
				left: 0;
				top: 0;
			}
			img {

				width: 100%;
				height: auto;
			}
			&:after{
				content: '';
				display: inline-block;
				padding-top: 100%;
			}
		}
		@include media(sp){
			width: 30%;
			height: 25vw;
			margin-bottom: 4vw;
		}

	}
}

.pic-main {
	position: relative;
	max-height: 390px;
	height: 33vw;
	overflow: hidden;
	margin: 15px 15px 0 15px;
	@include media(sp){
		height: 57vw;
		margin: 10px 10px 0 10px;
	}

	h1 {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		text-align: center;
	}
	&.about {

	}
}

.box-sound {
	background-color: #000;
	width: 100%;
	position: relative;
	&:after {
		content: '';
		display: inline-block;
		padding-top: 48.276%;
		@include media(sp){
			padding-top: 100%;
		}
	}
	> div {
		position: absolute;
		overflow: hidden;
		width: 50%;
		height: 100%;
		text-align: center;
		cursor: pointer;
		@include trans( width, 0.5s, 0s, $easeInOutCubic);
		&:after {
			@include trans( opacity, 0.25s, 0s, $easeInOutCubic);
			opacity: 0;
			content: '';
			position: absolute;
			left: 20px;
			right: 20px;
			top: 20px;
			bottom: 20px;
			border: 2px solid #fff;
		}
		&:hover {
			&:after {
				opacity: 1;
			}
		}
		&.show {
			width: 100%;
			.sound-control {
				background-image: url(/assets/images/about/ico-stop.png);
				width: 45px;
				height: 46px;
				margin-top: -25px;
				margin-left: -22px;
				@include media(sp){
					width: 34px;
					height: 34px;
					margin-top: -25px;
					margin-left: -17px;
				}
			}
			.sound-bg {
				opacity: 1;
			}
		}
		&.hide {
			width: 0;
		}
	}
	.sound-txt {
		position: absolute;
		left: 0;
		right: 0;
		top: 50%;
		margin-top: 55px;
	}
	.sound-control {
		background-image: url(/assets/images/about/ico-start.png);
		background-size: 100%;
		width: 60px;
		height: 69px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -50px;
		margin-left: -30px;

		@include media(sp){
			width: 45px;
			height: 51px;
			margin-top: -30px;
			margin-left: -22.5px;
		}
	}
	.sound-bg {
		@include trans( opacity, 0.5s, 0s, $easeInOutCubic);
		opacity: 0.5;
	}

	.sound-kuri {
		position: absolute;
		left: 0;
		top: 0;
		.sound-bg {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-image: url(/assets/images/about/pic-kuri.jpg);
			background-size: cover;
			background-position: left top;
			background-repeat: no-repeat;
			@include media(sp){
				background-image: url(/assets/images/about/pic-kuri-sp.jpg);
			}
		}
	}
	.sound-hinoki {
		position: absolute;
		right: 0;
		top: 0;
		.sound-bg {
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background-image: url(/assets/images/about/pic-hinoki.jpg);
			background-size: cover;
			background-position: right top;
			background-repeat: no-repeat;
			@include media(sp){
				background-image: url(/assets/images/about/pic-hinoki-sp.jpg);
			}
		}
	}

}

// flow

.text-flow {
	.num {
		margin-bottom: 26px;
	}
}

// contact

.box-contact {
	dl{
		display: table;
		width: 100%;
		margin-bottom: 20px;
		dt {
			display: table-cell;
			text-align: right;
			@include font(16,24,100);
			color: #fff;
			font-weight: bold;
			padding-right: 20px;
			vertical-align: top;
			padding-top: 10px;
		}
		dd {
			width: 65%;
			display: table-cell;
			max-width: 420px;
			> div{
				width: 100%;
				max-width: 420px;
				position: relative;
				display: inline-block;
			}
			input,textarea {
				width: 100%;
				max-width: 420px;
				background-color: #fff;
				border: 3px solid #000;
				border-radius: 0;
				@include font(16,24,100);
				padding: 10px 20px;
				box-sizing: border-box;
				&:focus{
					outline: none;
				}
			}
			input {
				height: 44px;
			}
			textarea {
				height: 210px;
			}
			select{
				-webkit-appearance: button;
				-moz-appearance: button;
				appearance: button;
				vertical-align: middle;
				box-sizing: border-box;
				width: 100%;
				max-width: 420px;
				padding: 9px 20px;
				border: 3px solid #000;
				border-radius: 0;
				background-color: #fff;
				background-image: url(/assets/images/contact/ico-select.png);
				background-position: right center;
				background-repeat: no-repeat;
				background-size: 32px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				@include font(16,16,100);

				&:focus{
					outline: none;
				}
			}
			div.error {
				position: absolute;
				right: 0;
				bottom: -19px;
				display: block;
				background-color: #fff;
				@include font(12,12,100);
				color: #c00;
				border: 3px solid #000;
				padding: 2px;

			}
		}
		&.last {
			margin-bottom: 60px;
		}
	}
	// button {
	// 	background-color: transparent;
	// 	width: 100%;
	// 	border: 2px solid #fff;
	// }

	.button {
		margin-left: 35%;
	}
	
	@include media(sp){
		dl{
			display: block;
			margin-bottom: 15px;
			dt {
				display: block;
				text-align: left;
				@include font(12,20,100);
				padding-top: 0;
				margin-bottom: 10px;
			}
			dd {
				width: 100%;
				display: block;
				input,textarea {
					border: 1px solid #000;
					@include font(12,20,100);
					padding: 5px 10px;
				}
				input {
					height: 35px;
				}
				textarea {
					height: 155px;
				}
				select{
					padding: 8px 10px;
					border: 1px solid #000;

					background-size: 24px;
					@include font(12,20,100);
				}
			}
			&.last {
				margin-bottom: 40px;
			}
		}
		.button {
			margin-left: auto;
			margin-right: auto;
		}
	}

}

