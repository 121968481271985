@charset "utf-8";
///////////////////////////////////////////
// motions.scss
///////////////////////////////////////////
.fadeInOut-scene {
	// opacity: 0;
	// transform: scale();
	// $selector: transform,opacity;
	// @include trans( $selector, 1s, 0s, $easeInOutCubic);
	// &.show{
	// 	@include trans( $selector, 1s, 0s, $easeInOutCubic);
	// 	opacity: 1;
	// 	transform: scale(1);
	// }
	// &.hide{
	// 	@include trans( $selector, 1s, 0s, $easeInOutCubic);
	// 	opacity: 0;
	// 	transform: scale(1);
	// }
}

.fadeInOut-inner {
	opacity: 0;
	// transform: translate3d(0,40px,0);
	transform: scale(0.8);
	$selector: transform,opacity;
	@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
	&.show{
		@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
		opacity: 1;
		transform: translate3d(0,0,0) scale(1);
		// transform: translate3d(0,0,0);
	}
	&.hide{
		@include trans( $selector, 0.6s, 0s, $easeInOutCubic);
		opacity: 0;
		transform: scale(0.1);
		// transform: translate3d(0,-40px,0);
	}
}

.fadeInOut-inner2 {
	opacity: 0;
	// transform: translate3d(0,40px,0);
	// transform: scale(0.8);
	$selector: transform,opacity;
	@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
	&.show{
		// @include trans( $selector, 0.3s, 0s, $easeInOutCubic);
		opacity: 1;
		// transform: translate3d(0,0,0);
		// transform: translate3d(0,0,0);
	}
	&.hide{
		// @include trans( $selector, 0.6s, 0s, $easeInOutCubic);
		// opacity: 0;
		// transform: scale(0.1);
		// transform: translate3d(0,-40px,0);
	}
}

.fadeInOut-inner.top-sp {
	opacity: 0;
	transform: translate3d(0,40px,0) scale(1);
	$selector: transform,opacity;
	@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
	&.show{
		@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
		opacity: 1;
		transform: translate3d(0,0,0) scale(1);
		// transform: translate3d(0,0,0);
	}
	&.hide{
		@include trans( $selector, 0.6s, 0s, $easeInOutCubic);
		opacity: 0;
		transform:translate3d(0,-40px,0) scale(1);
	}
}

.fadeInOut-modal {
	opacity: 0;
	transform: scale(0.8);
	$selector: transform,opacity;
	@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
	&.show{
		@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
		opacity: 1;
		transform: scale(1);
	}
}

.fadeInOut-modalbg {
	opacity: 0;
	$selector: transform,opacity;
	@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
	&.show{
		@include trans( $selector, 0.3s, 0s, $easeInOutCubic);
		opacity: 1;
	}
}

.fadeOut-pic-modal {
	opacity: 1;
	$selector: transform,opacity;
	@include trans( $selector, 2s, 0s, $easeInOutCubic);
	&.hide{
		opacity: 0;
	}
}

.fadeOut-diagnosis {
	$selector: transform,opacity;
	opacity: 0;
	transform: translate3d(10px,0,0);
	&.show{
		@include trans( $selector, 0.5s, 0s, $easeInOutCubic);
		opacity: 1;
		transform: translate3d(0,0,0);
	}
	&.hide{
		@include trans( $selector, 0.5s, 0s, $easeInOutCubic);
		opacity: 0;
		transform: translate3d(-10px,0,0);
	}
}

.fadeOut-thankyou {
	$selector: transform,opacity;
	opacity: 0;
	transform: translate3d(-100px,0,0);
	&.show{
		@include trans( $selector, 0.8s, 0s, $easeInOutCubic);
		opacity: 1;
		transform: translate3d(0,0,0);
	}
	&.hide{
		// @include trans( $selector, 0.8s, 0s, $easeInOutCubic);
		// opacity: 0;
		// transform: translate3d(0,0,0);
		@include animation(thankyou 4s ease 1);
	}
}

.fadeOut-thankyou2 {
	$selector: transform,opacity;
	opacity: 0;
	transform: scale(0.5);
	&.show{
		@include trans( $selector, 0.7s, 0s, $easeCustom);
		opacity: 1;
		transform: scale(1);
	}
	&.hide{
		@include trans( $selector, 0.7s, 0s, $easeCustom);
		opacity: 0;
		transform: scale(0.5);
	}
}

.fadeInOut-news-share {
	$selector: transform,opacity;
	opacity: 0;
	transform: translate3d(0,60px,0);
	@include trans( $selector, 0.5s, 0s, $easeInOutCubic);
	&.show{
		opacity: 1;
		transform: translate3d(0,0,0);
	}
}

.damage {
	@include animation(damage .1s linear 5);
}

.hover-down{
	transform: translate3d(0,0,0);
	@include trans(transform, 0.3s, 0s,$easeOutCubic);
	&:hover{
		transform: translate3d(0,-5px,0);
	}	
}

.scrolldown {
	@include animation(scrolldown 3s $easeOutCubic infinite);
}


.s_s {
	$selector: transform,opacity;
	opacity: 0;
	transform: translate3d(0,25px,0);
	@include trans( $selector, 1.25s, 0s, $easeInOutCubic);
	&.show{
		opacity: 1;
		transform: translate3d(0,0,0);
		&.edge {
			&:after {
				@include trans( $selector, 0.75s, 1s, $easeInOutCubic);
				transform: translate3d(0,0,0);
			}
		}
	}
}


