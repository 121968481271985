@charset "utf-8";

// Footer

.footer {
	background-color: #1e1003;
	padding: 40px 0 36px 0;
	@include media(sp){
		padding: 25px 0;
	}
	p{
		@include font(16,16,100);
		font-weight: bold;
		text-align: right;
		color: #fff;
		font-family: $font-en;
		@include media(sp){
			@include font(12,12,100);
		}
	}
}

#screen {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	display: none;
	z-index: 100000000;
	div {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		transform: translate3d(-100%,0,0);
		background-color: #fff;
	}
	#screen-inner1 {
		@include trans( transform, 0.6s, 0s, $easeInOutCubic);
		opacity: 0.5;
	}
	#screen-inner2 {
		@include trans( transform, 0.4s, 0.4s, $easeInOutCubic);
		opacity: 1;
	}
	&.show {
		div{
			transform: translate3d(0,0,0);
		}
	}
}