@charset "utf-8";

// button

.button {
	max-width: 334px;
	height: 54px;
	position: relative;
	@include media(sp){
		max-width: 255px;
		height: 45px;
	}
	a {
		width: 100%;
		height: 100%;
		display: block;
		border: 3px solid #1e1003;
		box-sizing: border-box;
		position: relative;
		@include media(sp){
			border: 2px solid #1e1003;
		}
		@include trans( transform, 0.3s, 0s, $easeInOutCubic);
		&:hover {
			transform: translate3d(2px,2px,0);
		}

	}
	img {
	  position: relative;
	  top: 50%;
	  transform: translateY(-50%);		
	}
	&:after {
		content: '';
		position: absolute;
		width: 5px;
		height: 100%;
		background-color: #1e1003;
		right: -5px;
		top: 5px;
		@include media(sp){
			width: 4px;
			right: -4px;
			top: 4px;
		}
	}
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 5px;
		background-color: #1e1003;
		left: 5px;
		bottom: -5px;
		@include media(sp){
			height: 4px;
			left: 4px;
			bottom: -4px;
		}
	}
	&.white {
		a {
			border: 3px solid #fff;
			@include media(sp){
				border: 2px solid #fff;			
			}
		}
		&:after {
			background-color: #fff;
		}
		&:before {
			background-color: #fff;
		}
	}
	&.insta {
		width: 17.2%;
		height: 17.2vw;
		max-height: 145px;
		// height: 145px;
		background-color: transparent;
		a {
			display: block;
			width: 100%;
			height: 100%;
			border: 3px solid #fff;
			box-sizing: border-box;
			text-align: center;
		}
		img {
			// margin-top: -40px;
		  top: 40%;

		}
		.ico-arrow-button{
			margin-top: 10px;
		}
	}
	&.center {
		margin-left: auto;
		margin-right: auto;
	}

	.ico-arrow-button{
		right: 24px;
	}
}

.list-button {
	display: flex;
	justify-content: space-between;
	// flex-wrap: wrap;
	width: 100%;
	@include media(sp){
		display: block;
	}
	.button{
		width: 255px;
		height: 60px;
		@include media(sp){
			width: 100%;
			max-width: 100%;
			height: 45px;
			margin-bottom: 15px;
		}
		img {
			margin-left: 26px;
		}
	}
}

.button2 {
	height: 145px;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	@include media(sp){
		height: 110px;
	}
	a {
		width: 100%;
		height: 100%;
		display: block;
		&:before {
			content: '';
			position: absolute;
			left: 10px;
			top: 10px;
			right: 10px;
			bottom: 10px;
			border: 2px solid #fff;
			@include media(sp){
				border: 1px solid #fff;
			}
		}
	}
	img {
	  position: relative;
	  top: 50%;
	  transform: translateY(-50%);
	  margin-left: 50px;
	}
	&.news1 {
		background-image: url(/assets/images/common/bg-news1.jpg);
	}
	&.news2 {
		background-image: url(/assets/images/common/bg-news2.jpg);
	}
	.ico-arrow-button{
		right: 60px;
		@include media(sp){
			right: 43px;
		}
	}
}

.button-share {
	display: flex;
	justify-content: space-between;
	width: 100%;
	li {
		width: 48.8%;
		height: 70px;
		max-width: 205px;
		position: relative;
		@include media(sp){
			height: 53px;
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			&:before {
				content: '';
				width: 1px;
				height: 100%;
				background-color: rgba(#fff,0.3);
				position: absolute;
				left: 70px;
				top: 0;
				@include media(sp){
					left: 50px;
				}
			}
		}
		&.button-twitter {
			background-color: #00aced;
			a{
				background-image: url(/assets/images/common/ico-twitter.png);
				background-size: 23px;
				background-position: 26px 26px;
				@include media(sp){
					background-size: 17px;
					background-position: 19px 20px;

				}
			}
		}
		&.button-facebook {
			background-color: #305097;
			a{
				background-image: url(/assets/images/common/ico-facebook.png);
				background-size: 11px;
				background-position: 30px 23px;
				@include media(sp){
					background-size: 8px;
					background-position: 22px 18px;

				}
			}
		}
		img {
		  position: relative;
		  top: 50%;
		  transform: translateY(-50%);
		  margin-left: 43%;
			@include media(sp){
			  margin-left: 45%;
			}

		}
		.ico-arrow-button{
			right: 20px;
			@include media(sp){
				right: 10px;
			}

		}
	}
}

.ico-arrow-button{
	@include trans( transform, 0.3s, 0s, $easeInOutCubic);
	display: block;
	background-image: url(/assets/images/common/ico-arrow-button.png);
	background-repeat: no-repeat;
	background-size: 100%;
	width: 24px;
	height: 15px;
	position: absolute;
	top: 50%;
	margin-top: -8px;
	&.white {
		background-image: url(/assets/images/common/ico-arrow-button2.png);
	}
	@include media(sp){
		width: 17px;
		height: 11px;
		position: absolute;
		margin-top: -5px;
	}
}



.menu-list,
.button2,
.button-share{
	a:hover{
		.ico-arrow-button{
		  transform: translate3d(3px,0,0);
		}
	}
}


.ico-arrow-button-v{
	display: block;
	background-image: url(/assets/images/common/ico-arrow-button3.png);
	background-repeat: no-repeat;
	background-size: 100%;
	width: 14px;
	height: 22px;
	position: absolute;
	top: 50%;
	right: 23px;
	margin-top: -11px;
	@include media(sp){
		width: 10px;
		height: 17px;
		right: 15px;
		position: absolute;
		margin-top: -8px;
	}
}

