@charset "utf-8";
///////////////////////////////////////////
// utilities.scss
///////////////////////////////////////////

.tar { text-align:right; }
.tac { text-align:center; }
.fll{ float:left; }
.flr{ float:right; }

.posr {
	position: relative;
}

.clearfix:after {
	height: 0;
	visibility: hidden;
	content: ".";
	display: block;
	clear: both;
	font-size:0;
	line-height:0;
	overflow: hidden;
}

.clearfix {
	_height: 1px;
	min-height: 1px;/*・･*//*/
  height: auto;
  overflow: hidden;
  /**/
}

.show_pc {
	display:block;
	@include media(tb){
		display:none;
	}
	@include media(sp){
		display:none;
	}
}

.show_pctb {
	display:block;
	@include media(tb){
		display:block;
	}
	@include media(sp){
		display:none;
	}
}

.show_tbsp {
	display:none;
	@include media(tb){
		display:block;
	}
	@include media(sp){
		display:block;
	}
}

.show_tb {
	display:none;
	@include media(tb){
		display:block;
	}
	@include media(sp){
		display:none;
	}
}

.show_sp {
	display:none;
	@include media(tb){
		display:none;
	}
	@include media(sp){
		display:block;
	}
}

@include media(pc){
	// .op{
	// 	.op-target{
	// 		@include trans(opacity,0.75s,0,ease);
	// 	}
	// }
	// .op:hover{
	// 	.op-target{
	// 		opacity: 0.5;
	// 	}
	// }
	.op2{
		@include trans(opacity,.5s,0s,$easeOutCubic);
	}
	.op2:hover{
		opacity: 0.5;
	}
	.op a{
		display: block;
		background-color: #fff;
		img{
			@include trans(opacity,.5s,0s,$easeOutCubic);
		}
		&:hover	img {
			opacity: 0.6;
		}
	}
}


// スペーサー
@each $key, $value in $spacers {
  .spacer-bottom-#{$key} {
    margin-bottom: #{$value}px;
  }
  .spacer-top-#{$key} {
    margin-top: #{$value}px;
  }
}

@include media(sp){
	@each $key, $value in $spacersSP {
	  .spacer-bottom-#{$key} {
	    margin-bottom: #{$value}px;
	  }
	  .spacer-top-#{$key} {
	    margin-top: #{$value}px;
	  }
	}
}

// 画像置換






