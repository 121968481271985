@charset "UTF-8";
.sprite-basic {
  display: block;
  overflow: hidden;
  text-indent: -9999px; }

.sprite-basic-a {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-indent: -9999px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }

  100% {
    transform: rotate(360deg); } }

@keyframes flip {
  0% {
    transform: rotateY(0deg); }

  100% {
    transform: rotateY(360deg); } }

@keyframes scrolldown {
  0% {
    transform: translate3d(0, 0, 0); }

  50% {
    transform: translate3d(0, 7px, 0); }

  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes scrolldownSp {
  0% {
    transform: translate3d(0, 0, 0); }

  50% {
    transform: translate3d(0, -10px, 0); }

  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes scrollHand {
  0% {
    transform: rotate(0deg); }

  50% {
    transform: rotate(20deg); }

  100% {
    transform: rotate(0deg); } }

@keyframes flash {
  0% {
    opacity: 1; }

  50% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@keyframes damage {
  0% {
    transform: translate3d(0px, 0px, 0); }

  25% {
    transform: translate3d(10px, 0px, 0); }

  75% {
    transform: translate3d(-10px, 0px, 0); }

  100% {
    transform: translate3d(0px, 0px, 0); } }

@keyframes damage2 {
  0% {
    transform: translate3d(0px, 0px, 0); }

  25% {
    transform: translate3d(30px, 0px, 0); }

  75% {
    transform: translate3d(-30px, 0px, 0); }

  100% {
    transform: translate3d(0px, 0px, 0); } }

@keyframes attack {
  0% {
    transform: rotate(0deg); }

  25% {
    transform: rotate(30deg); }

  75% {
    transform: rotate(-90deg); }

  100% {
    transform: rotate(0deg); } }

@keyframes attack2 {
  0% {
    transform: scale(1); }

  25% {
    transform: scale(0.85); }

  100% {
    transform: scale(1); } }

@keyframes shine {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(0deg); }

  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg); } }

@keyframes shine30 {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(30deg); }

  100% {
    opacity: 1;
    transform: scale(1) rotate(30deg); } }

@keyframes shine60 {
  0% {
    opacity: 0;
    transform: scale(0.5) rotate(60deg); }

  100% {
    opacity: 1;
    transform: scale(1) rotate(60deg); } }

@keyframes loadinga {
  0% {
    background-position: 0px 0px; }

  100% {
    background-position: 0px -630px; } }

@keyframes thankyou {
  0% {
    transform: translate3d(0px, 0px, 0) scale(1);
    opacity: 1; }

  15% {
    transform: translate3d(100px, 0px, 0) scale(1.2);
    opacity: 1; }

  30% {
    transform: translate3d(-100px, 0px, 0) scale(1.4);
    opacity: 1; }

  45% {
    transform: translate3d(100px, 0px, 0) scale(1.6);
    opacity: 1; }

  60% {
    transform: translate3d(0px, 0px, 0) scale(1.8);
    opacity: 1; }

  100% {
    transform: translate3d(0px, 0px, 0) scale(3) rotate(30deg);
    opacity: 0; } }

.fadeInOut-inner {
  opacity: 0;
  transform: scale(0.8);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-inner.show {
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  .fadeInOut-inner.hide {
    transition-property: transform, opacity;
    transition-duration: 0.6s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    transform: scale(0.1); }

.fadeInOut-inner2 {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-inner2.show {
    opacity: 1; }

.fadeInOut-inner.top-sp {
  opacity: 0;
  transform: translate3d(0, 40px, 0) scale(1);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-inner.top-sp.show {
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  .fadeInOut-inner.top-sp.hide {
    transition-property: transform, opacity;
    transition-duration: 0.6s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    transform: translate3d(0, -40px, 0) scale(1); }

.fadeInOut-modal {
  opacity: 0;
  transform: scale(0.8);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-modal.show {
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: scale(1); }

.fadeInOut-modalbg {
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-modalbg.show {
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1; }

.fadeOut-pic-modal {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 2s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeOut-pic-modal.hide {
    opacity: 0; }

.fadeOut-diagnosis {
  opacity: 0;
  transform: translate3d(10px, 0, 0); }
  .fadeOut-diagnosis.show {
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .fadeOut-diagnosis.hide {
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    transform: translate3d(-10px, 0, 0); }

.fadeOut-thankyou {
  opacity: 0;
  transform: translate3d(-100px, 0, 0); }
  .fadeOut-thankyou.show {
    transition-property: transform, opacity;
    transition-duration: 0.8s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .fadeOut-thankyou.hide {
    -o-animation: thankyou 4s ease 1;
    -moz-animation: thankyou 4s ease 1;
    -webkit-animation: thankyou 4s ease 1;
    animation: thankyou 4s ease 1; }

.fadeOut-thankyou2 {
  opacity: 0;
  transform: scale(0.5); }
  .fadeOut-thankyou2.show {
    transition-property: transform, opacity;
    transition-duration: 0.7s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.065, 0.63, 0.065, 1.255);
    opacity: 1;
    transform: scale(1); }
  .fadeOut-thankyou2.hide {
    transition-property: transform, opacity;
    transition-duration: 0.7s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.065, 0.63, 0.065, 1.255);
    opacity: 0;
    transform: scale(0.5); }

.fadeInOut-news-share {
  opacity: 0;
  transform: translate3d(0, 60px, 0);
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .fadeInOut-news-share.show {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.damage {
  -o-animation: damage .1s linear 5;
  -moz-animation: damage .1s linear 5;
  -webkit-animation: damage .1s linear 5;
  animation: damage .1s linear 5; }

.hover-down {
  transform: translate3d(0, 0, 0);
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hover-down:hover {
    transform: translate3d(0, -5px, 0); }

.scrolldown {
  -o-animation: scrolldown 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  -moz-animation: scrolldown 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  -webkit-animation: scrolldown 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  animation: scrolldown 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }

.s_s {
  opacity: 0;
  transform: translate3d(0, 25px, 0);
  transition-property: transform, opacity;
  transition-duration: 1.25s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .s_s.show {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
    .s_s.show.edge:after {
      transition-property: transform, opacity;
      transition-duration: 0.75s;
      transition-delay: 1s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: translate3d(0, 0, 0); }

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, dialog, figure, footer, header, hgroup, menu, nav, section, menu, time, mark, audio, video, div, main {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background-color: transparent;
  box-sizing: border-box; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header, hgroup, nav, section {
  display: block; }

ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

img {
  vertical-align: bottom; }

html {
  font-size: 62.5%;
  /*10px*/ }

body {
  font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
  color: #1e1003;
  background: #fff;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

input {
  font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif; }

a {
  text-decoration: none;
  color: #1e1003; }

.underline {
  cursor: pointer;
  text-decoration: underline; }
  .underline:hover {
    text-decoration: none; }

a:visited {
  color: #1e1003; }

a:hover {
  color: #1e1003; }

::selection {
  background: #ddd; }

::-moz-selection {
  background: #ddd; }

.tar {
  text-align: right; }

.tac {
  text-align: center; }

.fll {
  float: left; }

.flr {
  float: right; }

.posr {
  position: relative; }

.clearfix:after {
  height: 0;
  visibility: hidden;
  content: ".";
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  overflow: hidden; }

.clearfix {
  _height: 1px;
  min-height: 1px;
  /*・･*/
  /*/
  height: auto;
  overflow: hidden;
  /**/ }

.show_pc {
  display: block; }
  @media only screen and (max-width: 979px) {
    .show_pc {
      display: none; } }
  @media only screen and (max-width: 680px) {
    .show_pc {
      display: none; } }

.show_pctb {
  display: block; }
  @media only screen and (max-width: 979px) {
    .show_pctb {
      display: block; } }
  @media only screen and (max-width: 680px) {
    .show_pctb {
      display: none; } }

.show_tbsp {
  display: none; }
  @media only screen and (max-width: 979px) {
    .show_tbsp {
      display: block; } }
  @media only screen and (max-width: 680px) {
    .show_tbsp {
      display: block; } }

.show_tb {
  display: none; }
  @media only screen and (max-width: 979px) {
    .show_tb {
      display: block; } }
  @media only screen and (max-width: 680px) {
    .show_tb {
      display: none; } }

.show_sp {
  display: none; }
  @media only screen and (max-width: 979px) {
    .show_sp {
      display: none; } }
  @media only screen and (max-width: 680px) {
    .show_sp {
      display: block; } }

@media only screen and (min-width: 980px) {
  .op2 {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .op2:hover {
    opacity: 0.5; }
  .op a {
    display: block;
    background-color: #fff; }
    .op a img {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
    .op a:hover img {
      opacity: 0.6; } }

.spacer-bottom-ss {
  margin-bottom: 10px; }

.spacer-top-ss {
  margin-top: 10px; }

.spacer-bottom-s {
  margin-bottom: 30px; }

.spacer-top-s {
  margin-top: 30px; }

.spacer-bottom-sl {
  margin-bottom: 40px; }

.spacer-top-sl {
  margin-top: 40px; }

.spacer-bottom-ms {
  margin-bottom: 50px; }

.spacer-top-ms {
  margin-top: 50px; }

.spacer-bottom-m {
  margin-bottom: 60px; }

.spacer-top-m {
  margin-top: 60px; }

.spacer-bottom-ml {
  margin-bottom: 90px; }

.spacer-top-ml {
  margin-top: 90px; }

.spacer-bottom-l {
  margin-bottom: 100px; }

.spacer-top-l {
  margin-top: 100px; }

.spacer-bottom-ll {
  margin-bottom: 110px; }

.spacer-top-ll {
  margin-top: 110px; }

.spacer-bottom-lll {
  margin-bottom: 120px; }

.spacer-top-lll {
  margin-top: 120px; }

@media only screen and (max-width: 680px) {
  .spacer-bottom-ss {
    margin-bottom: 5px; }
  .spacer-top-ss {
    margin-top: 5px; }
  .spacer-bottom-s {
    margin-bottom: 20px; }
  .spacer-top-s {
    margin-top: 20px; }
  .spacer-bottom-sl {
    margin-bottom: 30px; }
  .spacer-top-sl {
    margin-top: 30px; }
  .spacer-bottom-ms {
    margin-bottom: 40px; }
  .spacer-top-ms {
    margin-top: 40px; }
  .spacer-bottom-m {
    margin-bottom: 50px; }
  .spacer-top-m {
    margin-top: 50px; }
  .spacer-bottom-ml {
    margin-bottom: 80px; }
  .spacer-top-ml {
    margin-top: 80px; }
  .spacer-bottom-l {
    margin-bottom: 90px; }
  .spacer-top-l {
    margin-top: 90px; }
  .spacer-bottom-ll {
    margin-bottom: 100px; }
  .spacer-top-ll {
    margin-top: 100px; }
  .spacer-bottom-lll {
    margin-bottom: 110px; }
  .spacer-top-lll {
    margin-top: 110px; } }

.button {
  max-width: 334px;
  height: 54px;
  position: relative; }
  @media only screen and (max-width: 680px) {
    .button {
      max-width: 255px;
      height: 45px; } }
  .button a {
    width: 100%;
    height: 100%;
    display: block;
    border: 3px solid #1e1003;
    box-sizing: border-box;
    position: relative;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    @media only screen and (max-width: 680px) {
      .button a {
        border: 2px solid #1e1003; } }
    .button a:hover {
      transform: translate3d(2px, 2px, 0); }
  .button img {
    position: relative;
    top: 50%;
    transform: translateY(-50%); }
  .button:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 100%;
    background-color: #1e1003;
    right: -5px;
    top: 5px; }
    @media only screen and (max-width: 680px) {
      .button:after {
        width: 4px;
        right: -4px;
        top: 4px; } }
  .button:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #1e1003;
    left: 5px;
    bottom: -5px; }
    @media only screen and (max-width: 680px) {
      .button:before {
        height: 4px;
        left: 4px;
        bottom: -4px; } }
  .button.white a {
    border: 3px solid #fff; }
    @media only screen and (max-width: 680px) {
      .button.white a {
        border: 2px solid #fff; } }
  .button.white:after {
    background-color: #fff; }
  .button.white:before {
    background-color: #fff; }
  .button.insta {
    width: 17.2%;
    height: 17.2vw;
    max-height: 145px;
    background-color: transparent; }
    .button.insta a {
      display: block;
      width: 100%;
      height: 100%;
      border: 3px solid #fff;
      box-sizing: border-box;
      text-align: center; }
    .button.insta img {
      top: 40%; }
    .button.insta .ico-arrow-button {
      margin-top: 10px; }
  .button.center {
    margin-left: auto;
    margin-right: auto; }
  .button .ico-arrow-button {
    right: 24px; }

.list-button {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  @media only screen and (max-width: 680px) {
    .list-button {
      display: block; } }
  .list-button .button {
    width: 255px;
    height: 60px; }
    @media only screen and (max-width: 680px) {
      .list-button .button {
        width: 100%;
        max-width: 100%;
        height: 45px;
        margin-bottom: 15px; } }
    .list-button .button img {
      margin-left: 26px; }

.button2 {
  height: 145px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat; }
  @media only screen and (max-width: 680px) {
    .button2 {
      height: 110px; } }
  .button2 a {
    width: 100%;
    height: 100%;
    display: block; }
    .button2 a:before {
      content: '';
      position: absolute;
      left: 10px;
      top: 10px;
      right: 10px;
      bottom: 10px;
      border: 2px solid #fff; }
      @media only screen and (max-width: 680px) {
        .button2 a:before {
          border: 1px solid #fff; } }
  .button2 img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 50px; }
  .button2.news1 {
    background-image: url(/assets/images/common/bg-news1.jpg); }
  .button2.news2 {
    background-image: url(/assets/images/common/bg-news2.jpg); }
  .button2 .ico-arrow-button {
    right: 60px; }
    @media only screen and (max-width: 680px) {
      .button2 .ico-arrow-button {
        right: 43px; } }

.button-share {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .button-share li {
    width: 48.8%;
    height: 70px;
    max-width: 205px;
    position: relative; }
    @media only screen and (max-width: 680px) {
      .button-share li {
        height: 53px; } }
    .button-share li a {
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat; }
      .button-share li a:before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.3);
        position: absolute;
        left: 70px;
        top: 0; }
        @media only screen and (max-width: 680px) {
          .button-share li a:before {
            left: 50px; } }
    .button-share li.button-twitter {
      background-color: #00aced; }
      .button-share li.button-twitter a {
        background-image: url(/assets/images/common/ico-twitter.png);
        background-size: 23px;
        background-position: 26px 26px; }
        @media only screen and (max-width: 680px) {
          .button-share li.button-twitter a {
            background-size: 17px;
            background-position: 19px 20px; } }
    .button-share li.button-facebook {
      background-color: #305097; }
      .button-share li.button-facebook a {
        background-image: url(/assets/images/common/ico-facebook.png);
        background-size: 11px;
        background-position: 30px 23px; }
        @media only screen and (max-width: 680px) {
          .button-share li.button-facebook a {
            background-size: 8px;
            background-position: 22px 18px; } }
    .button-share li img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 43%; }
      @media only screen and (max-width: 680px) {
        .button-share li img {
          margin-left: 45%; } }
    .button-share li .ico-arrow-button {
      right: 20px; }
      @media only screen and (max-width: 680px) {
        .button-share li .ico-arrow-button {
          right: 10px; } }

.ico-arrow-button {
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  background-image: url(/assets/images/common/ico-arrow-button.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 24px;
  height: 15px;
  position: absolute;
  top: 50%;
  margin-top: -8px; }
  .ico-arrow-button.white {
    background-image: url(/assets/images/common/ico-arrow-button2.png); }
  @media only screen and (max-width: 680px) {
    .ico-arrow-button {
      width: 17px;
      height: 11px;
      position: absolute;
      margin-top: -5px; } }

.menu-list a:hover .ico-arrow-button, .button2 a:hover .ico-arrow-button, .button-share a:hover .ico-arrow-button {
  transform: translate3d(3px, 0, 0); }

.ico-arrow-button-v {
  display: block;
  background-image: url(/assets/images/common/ico-arrow-button3.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 14px;
  height: 22px;
  position: absolute;
  top: 50%;
  right: 23px;
  margin-top: -11px; }
  @media only screen and (max-width: 680px) {
    .ico-arrow-button-v {
      width: 10px;
      height: 17px;
      right: 15px;
      position: absolute;
      margin-top: -8px; } }

.bg-title {
  opacity: 0;
  transform: scale(1.05); }
  .bg-title.show {
    transition-property: transform, opacity;
    transition-duration: 1.5s;
    transition-delay: 0.5s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    transform: scale(1); }
  .bg-title.show2 {
    opacity: 1;
    transform: scale(1); }

.box-bg {
  position: relative;
  overflow: hidden;
  min-height: 420px; }
  @media only screen and (max-width: 680px) {
    .box-bg {
      min-height: 315px; } }

.bg {
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: -21%;
  height: 142%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

.bg-common-contact {
  background-image: url(/assets/images/common/bg-contact.jpg); }

.bg-common-instgagram {
  background-image: url(/assets/images/common/bg-instagram.jpg); }

.bg-top-flow {
  background-image: url(/assets/images/top/bg-flow.jpg); }

.bg-about {
  background-image: url(/assets/images/about/bg-about.jpg); }

.bg-about-1 {
  background-image: url(/assets/images/about/bg-about-1.jpg); }

.bg-about-2 {
  background-image: url(/assets/images/about/bg-about-2.jpg); }

.bg-about-3 {
  background-image: url(/assets/images/about/bg-about-3.jpg); }

.bg-flow {
  background-image: url(/assets/images/flow/bg-flow.jpg); }

.bg-flow-1 {
  background-image: url(/assets/images/flow/bg-flow-1.jpg); }

.bg-history {
  background-image: url(/assets/images/history/bg-history.jpg); }

.bg-history-1 {
  background-image: url(/assets/images/history/bg-history-1.jpg); }

.bg-history-2 {
  background-image: url(/assets/images/history/bg-history-2.jpg); }

.bg-history-3 {
  background-image: url(/assets/images/history/bg-history-3.jpg); }

.bg-history-4 {
  background-image: url(/assets/images/history/bg-history-4.jpg); }

.bg-contact {
  background-image: url(/assets/images/contact/bg-contact.jpg); }

.bg-contact-1 {
  background-image: url(/assets/images/contact/bg-contact-1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; }

html {
  height: 100%; }

img {
  width: 100%;
  height: auto; }

.wrapper {
  position: relative;
  height: 100%;
  width: 100%; }

.box {
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  padding: 120px 20px; }
  .box.pfix {
    padding: 0 20px; }
  .box.pfix2 {
    padding-bottom: 30px; }
  .box.pfix-flow0 {
    padding: 95px 20px 95px 20px; }
  .box.pfix-flow {
    padding: 90px 20px 105px 20px; }
  .box.pfix-flow2 {
    padding: 90px 20px 85px 20px; }
  .box .box-inner {
    max-width: 870px;
    margin-left: auto;
    margin-right: auto; }
  @media only screen and (max-width: 680px) {
    .box {
      padding: 70px 30px; }
      .box.pfix {
        padding: 0 30px; }
      .box.pfix2 {
        padding-bottom: 30px; }
      .box.pfix-flow0 {
        padding: 95px 30px 95px 30px; }
      .box.pfix-flow {
        padding: 90px 30px 105px 30px; }
      .box.pfix-flow2 {
        padding: 90px 30px 85px 30px; } }

.box-lnav {
  padding: 55px 20px 65px 20px;
  border-bottom: 1px solid #7f7f7f; }
  @media only screen and (max-width: 680px) {
    .box-lnav {
      padding: 40px 28px 44px 28px; } }
  .box-lnav .box-inner {
    max-width: 870px;
    margin-left: auto;
    margin-right: auto; }

#nav-top {
  border-top: 1px solid #7f7f7f;
  border-bottom: 1px solid #7f7f7f; }

.divide {
  border-bottom: 1px solid #000; }

.nav dl {
  height: 90px;
  max-width: 960px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .nav dl dt {
    margin-right: 50px;
    max-width: 182px;
    width: 22%; }
  .nav dl dd {
    font-size: 16px;
    line-height: 1.0625;
    letter-spacing: 0.1em;
    font-weight: bold;
    vertical-align: middle; }
@media only screen and (max-width: 979px) {
  .nav dl dt {
    margin-right: 15px; }
  .nav dl dd {
    font-size: 13px;
    line-height: 1.07692;
    letter-spacing: 0.01em; } }
@media only screen and (max-width: 680px) {
  .nav .box {
    padding: 0; }
  .nav dl {
    max-width: 100%;
    height: auto;
    display: block;
    align-items: left; }
    .nav dl img {
      margin-left: 0;
      width: 36%; }
    .nav dl dt {
      vertical-align: middle;
      margin-right: 0;
      max-width: 100%;
      padding: 0;
      width: 100%; }
      .nav dl dt a {
        display: block;
        padding: 30px; }
    .nav dl dd {
      border-top: 1px solid #7f7f7f;
      font-size: 12px;
      letter-spacing: 0.1em;
      font-weight: bold;
      vertical-align: middle; }
      .nav dl dd a {
        display: block;
        padding: 30px; } }

.box-image {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .box-image.box420 > div {
    width: 48%;
    max-width: 420px; }
  @media only screen and (max-width: 680px) {
    .box-image {
      display: block; }
      .box-image.box420 > div {
        width: 100%;
        max-width: 100%; }
        .box-image.box420 > div:first-child {
          margin-bottom: 25px; } }

.list-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -3.3%; }
  @media only screen and (max-width: 680px) {
    .list-image {
      margin-bottom: -4.7vw; } }
  .list-image li {
    width: 31%;
    max-width: 270px;
    margin-bottom: 3.3%; }
    @media only screen and (max-width: 680px) {
      .list-image li {
        width: 47.6%;
        margin-bottom: 4.7vw; } }

.h-style1 {
  margin-bottom: 45px; }
  @media only screen and (max-width: 680px) {
    .h-style1 {
      margin-bottom: 30px; } }
  .h-style1.mbfix {
    margin-bottom: 100px; }
    @media only screen and (max-width: 680px) {
      .h-style1.mbfix {
        margin-bottom: 75px; } }

.t-style1 {
  font-size: 16px;
  line-height: 2.5;
  letter-spacing: 0.1em;
  font-weight: bold; }
  @media only screen and (max-width: 680px) {
    .t-style1 {
      font-size: 12px;
      line-height: 2.5;
      letter-spacing: 0.1em; } }
  .t-style1.white {
    color: #fff; }

.w-half {
  width: 100%;
  max-width: 420px; }

.t-style2 {
  font-size: 16px;
  line-height: 2.5;
  letter-spacing: 0.1em;
  font-weight: bold;
  border: 2px solid #000;
  padding: 50px 58px;
  position: relative; }
  .t-style2:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    height: 2px;
    background-color: #000; }
  .t-style2:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    height: 2px;
    background-color: #000; }
  @media only screen and (max-width: 680px) {
    .t-style2 {
      font-size: 12px;
      line-height: 2.5;
      letter-spacing: 0.1em;
      border: 1px solid #000;
      padding: 36px 36px; }
      .t-style2:before {
        top: 15px;
        height: 1px; }
      .t-style2:after {
        bottom: 15px;
        height: 1px; } }

.box-two_column {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  @media only screen and (max-width: 680px) {
    .box-two_column {
      display: block; } }

.box-column {
  width: 48.3%; }
  @media only screen and (max-width: 680px) {
    .box-column {
      width: 100%; } }

.box-facebok {
  background-image: url(/assets/images/common/bg-facebook.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  height: 420px;
  padding: 30px; }
  @media only screen and (max-width: 680px) {
    .box-facebok {
      height: auto;
      padding: 22px;
      margin-bottom: 20px; } }

.edge {
  overflow: hidden;
  position: relative; }
  .edge:after {
    content: '';
    position: absolute;
    right: -1px;
    top: -1px;
    width: 0;
    height: 0;
    border-top: 30px solid #fff;
    border-right: 50px solid #fff;
    border-bottom: 30px solid transparent;
    border-left: 50px solid transparent;
    transform: translate3d(0, -60px, 0); }
    @media only screen and (max-width: 680px) {
      .edge:after {
        border-top: 22px solid #fff;
        border-right: 38px solid #fff;
        border-bottom: 22px solid transparent;
        border-left: 38px solid transparent;
        transform: translate3d(0, -45px, 0); } }
  .edge.small:after {
    border-top: 22px solid #fff;
    border-right: 37px solid #fff;
    border-bottom: 22px solid transparent;
    border-left: 37px solid transparent;
    transform: translate3d(0, -45px, 0); }
    @media only screen and (max-width: 680px) {
      .edge.small:after {
        border-top: 12px solid #fff;
        border-right: 22px solid #fff;
        border-bottom: 12px solid transparent;
        border-left: 22px solid transparent;
        transform: translate3d(0, -25px, 0); } }

.list-instagram {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -30px; }
  @media only screen and (max-width: 680px) {
    .list-instagram {
      margin-bottom: 8vw; } }
  .list-instagram li {
    width: 17.2%;
    background-color: #000;
    margin-bottom: 30px;
    position: relative; }
    .list-instagram li.pic-instagram {
      overflow: hidden; }
      .list-instagram li.pic-instagram a {
        display: block;
        position: absolute;
        left: 0;
        top: 0; }
      .list-instagram li.pic-instagram img {
        width: 100%;
        height: auto; }
      .list-instagram li.pic-instagram:after {
        content: '';
        display: inline-block;
        padding-top: 100%; }
    @media only screen and (max-width: 680px) {
      .list-instagram li {
        width: 30%;
        height: 25vw;
        margin-bottom: 4vw; } }

.pic-main {
  position: relative;
  max-height: 390px;
  height: 33vw;
  overflow: hidden;
  margin: 15px 15px 0 15px; }
  @media only screen and (max-width: 680px) {
    .pic-main {
      height: 57vw;
      margin: 10px 10px 0 10px; } }
  .pic-main h1 {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center; }

.box-sound {
  background-color: #000;
  width: 100%;
  position: relative; }
  .box-sound:after {
    content: '';
    display: inline-block;
    padding-top: 48.276%; }
    @media only screen and (max-width: 680px) {
      .box-sound:after {
        padding-top: 100%; } }
  .box-sound > div {
    position: absolute;
    overflow: hidden;
    width: 50%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    transition-property: width;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .box-sound > div:after {
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
      opacity: 0;
      content: '';
      position: absolute;
      left: 20px;
      right: 20px;
      top: 20px;
      bottom: 20px;
      border: 2px solid #fff; }
    .box-sound > div:hover:after {
      opacity: 1; }
    .box-sound > div.show {
      width: 100%; }
      .box-sound > div.show .sound-control {
        background-image: url(/assets/images/about/ico-stop.png);
        width: 45px;
        height: 46px;
        margin-top: -25px;
        margin-left: -22px; }
        @media only screen and (max-width: 680px) {
          .box-sound > div.show .sound-control {
            width: 34px;
            height: 34px;
            margin-top: -25px;
            margin-left: -17px; } }
      .box-sound > div.show .sound-bg {
        opacity: 1; }
    .box-sound > div.hide {
      width: 0; }
  .box-sound .sound-txt {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin-top: 55px; }
  .box-sound .sound-control {
    background-image: url(/assets/images/about/ico-start.png);
    background-size: 100%;
    width: 60px;
    height: 69px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -30px; }
    @media only screen and (max-width: 680px) {
      .box-sound .sound-control {
        width: 45px;
        height: 51px;
        margin-top: -30px;
        margin-left: -22.5px; } }
  .box-sound .sound-bg {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0.5; }
  .box-sound .sound-kuri {
    position: absolute;
    left: 0;
    top: 0; }
    .box-sound .sound-kuri .sound-bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url(/assets/images/about/pic-kuri.jpg);
      background-size: cover;
      background-position: left top;
      background-repeat: no-repeat; }
      @media only screen and (max-width: 680px) {
        .box-sound .sound-kuri .sound-bg {
          background-image: url(/assets/images/about/pic-kuri-sp.jpg); } }
  .box-sound .sound-hinoki {
    position: absolute;
    right: 0;
    top: 0; }
    .box-sound .sound-hinoki .sound-bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-image: url(/assets/images/about/pic-hinoki.jpg);
      background-size: cover;
      background-position: right top;
      background-repeat: no-repeat; }
      @media only screen and (max-width: 680px) {
        .box-sound .sound-hinoki .sound-bg {
          background-image: url(/assets/images/about/pic-hinoki-sp.jpg); } }

.text-flow .num {
  margin-bottom: 26px; }

.box-contact dl {
  display: table;
  width: 100%;
  margin-bottom: 20px; }
  .box-contact dl dt {
    display: table-cell;
    text-align: right;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1em;
    color: #fff;
    font-weight: bold;
    padding-right: 20px;
    vertical-align: top;
    padding-top: 10px; }
  .box-contact dl dd {
    width: 65%;
    display: table-cell;
    max-width: 420px; }
    .box-contact dl dd > div {
      width: 100%;
      max-width: 420px;
      position: relative;
      display: inline-block; }
    .box-contact dl dd input, .box-contact dl dd textarea {
      width: 100%;
      max-width: 420px;
      background-color: #fff;
      border: 3px solid #000;
      border-radius: 0;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.1em;
      padding: 10px 20px;
      box-sizing: border-box; }
      .box-contact dl dd input:focus, .box-contact dl dd textarea:focus {
        outline: none; }
    .box-contact dl dd input {
      height: 44px; }
    .box-contact dl dd textarea {
      height: 210px; }
    .box-contact dl dd select {
      -webkit-appearance: button;
      -moz-appearance: button;
      appearance: button;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      max-width: 420px;
      padding: 9px 20px;
      border: 3px solid #000;
      border-radius: 0;
      background-color: #fff;
      background-image: url(/assets/images/contact/ico-select.png);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 32px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 16px;
      letter-spacing: 0.1em; }
      .box-contact dl dd select:focus {
        outline: none; }
    .box-contact dl dd div.error {
      position: absolute;
      right: 0;
      bottom: -19px;
      display: block;
      background-color: #fff;
      font-size: 12px;
      letter-spacing: 0.1em;
      color: #c00;
      border: 3px solid #000;
      padding: 2px; }
  .box-contact dl.last {
    margin-bottom: 60px; }
.box-contact .button {
  margin-left: 35%; }
@media only screen and (max-width: 680px) {
  .box-contact dl {
    display: block;
    margin-bottom: 15px; }
    .box-contact dl dt {
      display: block;
      text-align: left;
      font-size: 12px;
      line-height: 1.66667;
      letter-spacing: 0.1em;
      padding-top: 0;
      margin-bottom: 10px; }
    .box-contact dl dd {
      width: 100%;
      display: block; }
      .box-contact dl dd input, .box-contact dl dd textarea {
        border: 1px solid #000;
        font-size: 12px;
        line-height: 1.66667;
        letter-spacing: 0.1em;
        padding: 5px 10px; }
      .box-contact dl dd input {
        height: 35px; }
      .box-contact dl dd textarea {
        height: 155px; }
      .box-contact dl dd select {
        padding: 8px 10px;
        border: 1px solid #000;
        background-size: 24px;
        font-size: 12px;
        line-height: 1.66667;
        letter-spacing: 0.1em; }
    .box-contact dl.last {
      margin-bottom: 40px; }
  .box-contact .button {
    margin-left: auto;
    margin-right: auto; } }

.footer {
  background-color: #1e1003;
  padding: 40px 0 36px 0; }
  @media only screen and (max-width: 680px) {
    .footer {
      padding: 25px 0; } }
  .footer p {
    font-size: 16px;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-align: right;
    color: #fff;
    font-family: Georgia, Times, "Times New Roman", serif; }
    @media only screen and (max-width: 680px) {
      .footer p {
        font-size: 12px;
        letter-spacing: 0.1em; } }

#screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: none;
  z-index: 100000000; }
  #screen div {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    transform: translate3d(-100%, 0, 0);
    background-color: #fff; }
  #screen #screen-inner1 {
    transition-property: transform;
    transition-duration: 0.6s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0.5; }
  #screen #screen-inner2 {
    transition-property: transform;
    transition-duration: 0.4s;
    transition-delay: 0.4s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1; }
  #screen.show div {
    transform: translate3d(0, 0, 0); }

.menu {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }
  @media only screen and (max-width: 680px) {
    .menu {
      width: 100%; } }

.menu-list {
  display: none;
  position: absolute;
  top: 0;
  right: 0; }
  @media only screen and (max-width: 680px) {
    .menu-list {
      width: 100%; } }
  .menu-list li {
    transform: translate3d(360px, 0, 0);
    position: relative;
    width: 360px;
    height: 80px;
    background-size: 100%;
    margin-bottom: 10px; }
    @media only screen and (max-width: 680px) {
      .menu-list li {
        transform: translate3d(100%, 0, 0);
        width: 100%;
        height: 60px;
        margin-bottom: 7.5px; } }
    .menu-list li span {
      display: inline-block;
      padding: 33px 84px 0 0;
      box-sizing: border-box; }
      @media only screen and (max-width: 680px) {
        .menu-list li span {
          padding: 24px 65px 0 0; } }
    .menu-list li a {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 16px;
      letter-spacing: 0.1em;
      color: #fff;
      font-weight: bold;
      text-align: right; }
      @media only screen and (max-width: 680px) {
        .menu-list li a {
          font-size: 12px;
          letter-spacing: 0.1em; } }
    .menu-list li.menu-top {
      background-image: url(/assets/images/common/bg-menu-top.jpg);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.4s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list li.menu-about {
      background-image: url(/assets/images/common/bg-menu-about.jpg);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.3s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list li.menu-flow {
      background-image: url(/assets/images/common/bg-menu-flow.jpg);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list li.menu-story {
      background-image: url(/assets/images/common/bg-menu-history.jpg);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.1s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list li.menu-contact {
      background-image: url(/assets/images/common/bg-menu-contact.jpg);
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .menu-list .ico-arrow-button {
    right: 30px; }
    @media only screen and (max-width: 680px) {
      .menu-list .ico-arrow-button {
        right: 24px; } }
  .menu-list.show li {
    transform: translate3d(0, 0, 0); }
    .menu-list.show li.menu-top {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list.show li.menu-about {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.1s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list.show li.menu-flow {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.2s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list.show li.menu-story {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.3s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-list.show li.menu-contact {
      transition-property: transform;
      transition-duration: 0.5s;
      transition-delay: 0.4s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

.menu-open {
  position: absolute;
  right: -60px;
  top: 0;
  width: 60px;
  height: 60px;
  background-color: #1e1003;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .menu-open i {
    display: block;
    position: absolute;
    left: 20px;
    top: 28px;
    width: 20px;
    height: 2px;
    background-color: #fff; }
    .menu-open i:before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: -8px;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
    .menu-open i:after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: 8px;
      transition-property: transform;
      transition-duration: 0.3s;
      transition-delay: 0s;
      transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .menu-open.show {
    transform: translate3d(-60px, 0, 0); }
  .menu-open:hover i:before {
    transform: translate3d(0, -2px, 0); }
  .menu-open:hover i:after {
    transform: translate3d(0, 2px, 0); }
  @media only screen and (max-width: 680px) {
    .menu-open {
      right: -45px;
      width: 45px;
      height: 45px; }
      .menu-open i {
        left: 15px;
        top: 22px;
        width: 15px;
        height: 1px; }
        .menu-open i:before {
          width: 15px;
          height: 1px;
          left: 0;
          top: -5px; }
        .menu-open i:after {
          width: 15px;
          height: 1px;
          left: 0;
          top: 5px; }
      .menu-open.show {
        transform: translate3d(-45px, 0, 0); } }

.menu-close {
  position: absolute;
  right: -60px;
  top: 450px;
  width: 60px;
  height: 60px;
  background-color: #1e1003;
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .menu-close i {
    position: absolute;
    left: 0;
    right: 0;
    display: block; }
    .menu-close i:before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 22px;
      top: 29px;
      transform: rotate(45deg); }
    .menu-close i:after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 22px;
      top: 29px;
      transform: rotate(-45deg); }
  .menu-close.show {
    transform: translate3d(-60px, 0, 0); }
  @media only screen and (max-width: 680px) {
    .menu-close {
      right: -45px;
      top: 337px;
      width: 45px;
      height: 45px; }
      .menu-close i:before {
        width: 15px;
        height: 1px;
        left: 16px;
        top: 22px; }
      .menu-close i:after {
        width: 15px;
        height: 1px;
        left: 16px;
        top: 22px; }
      .menu-close.show {
        transform: translate3d(-45px, 0, 0); } }

.white-screen {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: none;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .white-screen.show {
    opacity: 1; }

.lang {
  display: none;
  position: absolute;
  right: 62px;
  top: 35px;
  width: 100px; }
  .lang li {
    position: relative;
    float: left;
    margin-right: 20px;
    font-size: 14px;
    letter-spacing: 0.1em;
    font-weight: bold;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-style: italic; }
    .lang li.current:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      right: 0.1em;
      height: 2px;
      background-color: #1e1003; }

img[src*="top/txt-about0.png"] {
  width: 87%;
  max-width: 366px; }

img[src*="top/txt-about.png"] {
  width: 91%;
  max-width: 383px; }

img[src*="top/btn-about"] {
  width: 72%;
  max-width: 236px;
  margin-left: 25px; }

img[src*="top/txt-flow"] {
  width: 68%;
  max-width: 287px; }

img[src*="top/btn-flow"] {
  width: 32%;
  max-width: 104px;
  margin-left: 39px; }

img[src*="top/txt-story"] {
  width: 68%;
  max-width: 289px; }

img[src*="top/btn-story"] {
  width: 76%;
  max-width: 248px;
  margin-left: 25px; }

img[src*="common/txt-contact"] {
  width: 85.83%;
  max-width: 316px; }

img[src*="common/btn-contact"] {
  width: 38%;
  max-width: 123px;
  margin-left: 25px; }

img[src*="common/txt-news"] {
  width: 95%;
  max-width: 399px; }

img[src*="common/btn-news1"] {
  width: 31%;
  max-width: 122px; }

img[src*="common/btn-news2"] {
  width: 35%;
  max-width: 139px; }

img[src*="common/txt-twitter"] {
  width: 27%;
  max-width: 53px; }

img[src*="common/txt-facebook"] {
  width: 27%;
  max-width: 53px; }

img[src*="common/txt-instagram"] {
  width: 52%;
  max-width: 228px; }

img[src*="common/btn-instagram"] {
  max-width: 83px; }
  @media only screen and (max-width: 680px) {
    img[src*="common/btn-instagram"] {
      width: 24%;
      margin-left: 25px; } }

img[src*="about/txt-title"] {
  width: 83.83%;
  max-width: 448px; }

img[src*="about/txt-anc-1"] {
  max-width: 34px; }
  @media only screen and (max-width: 680px) {
    img[src*="about/txt-anc-1"] {
      width: 8.5%; } }

img[src*="about/txt-anc-2"] {
  max-width: 173px; }
  @media only screen and (max-width: 680px) {
    img[src*="about/txt-anc-2"] {
      width: 42%; } }

img[src*="about/txt-anc-3"] {
  max-width: 95px; }
  @media only screen and (max-width: 680px) {
    img[src*="about/txt-anc-3"] {
      width: 24.9%; } }

img[src*="about/txt-1"] {
  width: 81.4%;
  max-width: 343px; }

img[src*="about/txt-2"] {
  width: 84.4%;
  max-width: 356px; }

img[src*="about/txt-3"] {
  width: 68.2%;
  max-width: 287px; }

img[src*="about/txt-kuri"] {
  width: 65.7%;
  max-width: 135px; }

img[src*="about/txt-hinoki"] {
  width: 78.7%;
  max-width: 162px; }

img[src*="flow/txt-title.png"] {
  width: 38%;
  max-width: 187px; }

img[src*="flow/txt-1.png"] {
  width: 12%;
  max-width: 52px; }

img[src*="flow/txt-1_2.png"] {
  width: 30.6%;
  max-width: 130px; }

img[src*="flow/txt-2.png"] {
  width: 14.1%;
  max-width: 61px; }

img[src*="flow/txt-2_2.png"] {
  width: 28.5%;
  max-width: 121px; }

img[src*="flow/txt-3.png"] {
  width: 14.2%;
  max-width: 61px; }

img[src*="flow/txt-3_2.png"] {
  width: 17.4%;
  max-width: 74px; }

img[src*="flow/txt-4.png"] {
  width: 14.2%;
  max-width: 61px; }

img[src*="flow/txt-4_2.png"] {
  width: 38%;
  max-width: 161px; }

img[src*="flow/btn-contact.png"] {
  width: 31.7%;
  max-width: 105px;
  margin-left: 25px; }

img[src*="history/txt-title"] {
  width: 67%;
  max-width: 506px;
  margin-left: auto;
  margin-right: auto; }

img[src*="history/txt-anc-1"] {
  max-width: 101px; }

img[src*="history/txt-anc-2"] {
  max-width: 89px; }

img[src*="history/txt-anc-3"] {
  max-width: 164px; }

img[src*="history/txt-1"] {
  max-width: 288px; }

img[src*="history/txt-2"] {
  max-width: 94px; }

img[src*="history/txt-3"] {
  max-width: 71px; }

img[src*="history/txt-4"] {
  max-width: 186px; }

img[src*="contact/txt-title.png"] {
  width: 37.6%;
  max-width: 188px; }

img[src*="contact/txt-1.png"] {
  max-width: 393px; }

img[src*="contact/btn-toppage.png"] {
  max-width: 166px;
  margin-left: 30px; }

img[src*="contact/btn-submit.png"] {
  width: 48%;
  max-width: 155px;
  margin-left: 25px; }

#top-main {
  height: 100%;
  position: relative;
  overflow: hidden; }
  #top-main:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0 solid #fff;
    transition-property: border;
    transition-duration: 0.5s;
    transition-delay: 1.5s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  #top-main h1 {
    position: absolute;
    max-width: 516px;
    width: 68.5%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0); }
  #top-main #bg-top-main {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url(/assets/images/top/pic-main.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transform: scale(1.1);
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-delay: 0s;
    transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  #top-main.show:after {
    border: 15px solid #fff; }
    @media only screen and (max-width: 680px) {
      #top-main.show:after {
        border: 12px solid #fff; } }
  #top-main.show #bg-top-main {
    transform: scale(1);
    opacity: 1; }
  #top-main.show #top-main-arrow {
    transform: translate3d(0, 0, 0);
    opacity: 1; }

.ico-arrow-top, #top-main-arrow, #top-main-arrow:before, #top-main-arrow:after {
  background-image: url(/assets/images/top/ico-arrow-top.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 30px;
  height: 16px; }
  @media only screen and (max-width: 680px) {
    .ico-arrow-top, #top-main-arrow, #top-main-arrow:before, #top-main-arrow:after {
      width: 23px;
      height: 12px; } }

#top-main-arrow {
  transition-property: transform, opacity;
  transition-duration: 1s;
  transition-delay: 3s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translate3d(0, 10px, 0);
  opacity: 0;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: 57px; }
  @media only screen and (max-width: 680px) {
    #top-main-arrow {
      margin-left: -12px;
      bottom: 40px; } }
  #top-main-arrow:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0; }
    @media only screen and (max-width: 680px) {
      #top-main-arrow:before {
        top: -8px; } }
  #top-main-arrow:after {
    content: '';
    position: absolute;
    top: 10px;
    left: 0; }
    @media only screen and (max-width: 680px) {
      #top-main-arrow:after {
        top: 8px; } }

