@charset "utf-8";
///////////////////////////////////////////
// keyframes.scss
///////////////////////////////////////////

@keyframes spin {
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}

@keyframes flip {
	0%{
		transform: rotateY(0deg);
	}
	100%{
		transform: rotateY( 360deg );
	}
}

@keyframes scrolldown {
	0%{
		transform :translate3d(0, 0, 0);
	}
	50%{
		transform :translate3d(0, 7px, 0);
	}
	100%{
		transform :translate3d(0, 0, 0);
	}
}

@keyframes scrolldownSp {
	0%{
		transform :translate3d(0, 0, 0);
	}
	50%{
		transform :translate3d(0, -10px, 0);
	}
	100%{
		transform :translate3d(0, 0, 0);
	}
}

@keyframes scrollHand {
	0%{
		transform: rotate(0deg);
	}
	50%{
		transform: rotate(20deg);
	}
	100%{
		transform: rotate(0deg);
	}
}

// @keyframes scrolldownSP {
// 	0%{
// 		transform :translate3d(0, 0, 0);
// 	}
// 	100%{
// 		transform :translate3d(0, 8px, 0);
// 	}
// }

// @keyframes flash {
// 	0%{
// 		transform: scale(1);
// 	}
// 	100%{
// 		transform: scale(1.5);
// 	}
// }

@keyframes flash {
	0%{
		opacity: 1;
	}
	50%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}



@keyframes damage {
	0%{
		transform :translate3d(0px, 0px, 0);
	}
	25%{
		transform :translate3d(10px, 0px, 0);
	}
	75%{
		transform :translate3d(-10px, 0px, 0);
	}	
	100%{
		transform :translate3d(0px, 0px, 0);
	}
}

@keyframes damage2 {
	0%{
		transform :translate3d(0px, 0px, 0);
	}
	25%{
		transform :translate3d(30px, 0px, 0);
	}
	75%{
		transform :translate3d(-30px, 0px, 0);
	}	
	100%{
		transform :translate3d(0px, 0px, 0);
	}
}

@keyframes attack {
	0%{
		transform :rotate(0deg);
	}
	25%{
		transform :rotate(30deg);
	}
	75%{
		transform :rotate(-90deg);
	}	
	100%{
		transform :rotate(0deg);
	}
}

@keyframes attack2 {
	0%{
		transform: scale(1);
	}
	25%{
		transform: scale(0.85);
	}
	100%{
		transform: scale(1);
	}
}

@keyframes shine {
	0%{
		opacity: 0;
		transform: scale(0.5) rotate(0deg);
	}
	100%{
		opacity: 1;
		transform: scale(1) rotate(0deg);
	}
}

@keyframes shine30 {
	0%{
		opacity: 0;
		transform: scale(0.5) rotate(30deg);
	}
	100%{
		opacity: 1;
		transform: scale(1) rotate(30deg);
	}
}
@keyframes shine60 {
	0%{
		opacity: 0;
		transform: scale(0.5) rotate(60deg);
	}
	100%{
		opacity: 1;
		transform: scale(1) rotate(60deg);
	}
}
@keyframes loadinga {
  0% {
    background-position: 0px 0px; }
  100% {
    background-position: 0px -630px; } 
}

@keyframes thankyou {
	0%{
		transform :translate3d(0px, 0px, 0) scale(1);
		opacity: 1;
	}
	15%{
		transform :translate3d(100px, 0px, 0) scale(1.2);
		opacity: 1;
	}
	30%{
		transform :translate3d(-100px, 0px, 0) scale(1.4);
		opacity: 1;
	}	
	45%{
		transform :translate3d(100px, 0px, 0) scale(1.6);
		opacity: 1;
	}	
	60%{
		transform :translate3d(0px, 0px, 0) scale(1.8);
		opacity: 1;
	}		
	100%{
		transform :translate3d(0px, 0px, 0) scale(3) rotate(30deg);
		opacity: 0;
	}
}

