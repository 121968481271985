@charset "utf-8";

// Header

.menu {
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;
	@include media(sp){
		width: 100%;
	}
}

.menu-list {
	display: none;
	position: absolute;
	top: 0;
	right: 0;
	@include media(sp){
		width: 100%;
	}
	li {
		transform: translate3d(360px ,0,0);
		position: relative;
		width: 360px;
		height: 80px;
		background-size: 100%;
		margin-bottom: 10px;
		@include media(sp){
			transform: translate3d(100% ,0,0);
			width: 100%;
			height: 60px;
			margin-bottom: 7.5px;
		}

		span {
			display: inline-block;
			padding: 33px 84px 0 0;
			box-sizing: border-box;
			@include media(sp){
				padding: 24px 65px 0 0;
			}

		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			@include font(16,16,100);
			color: #fff;
			font-weight: bold;
			text-align: right;
			@include media(sp){
				@include font(12,12,100);
			}
		}
		&.menu-top {
			background-image: url(/assets/images/common/bg-menu-top.jpg);
			@include trans( transform, 0.5s, 0.4s, $easeInOutCubic);
		}
		&.menu-about {
			background-image: url(/assets/images/common/bg-menu-about.jpg);
			@include trans( transform, 0.5s, 0.3s, $easeInOutCubic);
		}
		&.menu-flow {
			background-image: url(/assets/images/common/bg-menu-flow.jpg);
			@include trans( transform, 0.5s, 0.2s, $easeInOutCubic);
		}
		&.menu-story {
			background-image: url(/assets/images/common/bg-menu-history.jpg);
			@include trans( transform, 0.5s, 0.1s, $easeInOutCubic);
		}
		&.menu-contact {
			background-image: url(/assets/images/common/bg-menu-contact.jpg);
			@include trans( transform, 0.5s, 0s, $easeInOutCubic);
		}
	}
	.ico-arrow-button{
		right: 30px;
		@include media(sp){
			right: 24px;
		}
	}
	&.show {
		li {
			transform: translate3d(0 ,0,0);
			&.menu-top {
				@include trans( transform, 0.5s, 0s, $easeInOutCubic);
			}
			&.menu-about {
				@include trans( transform, 0.5s, 0.1s, $easeInOutCubic);
			}
			&.menu-flow {
				@include trans( transform, 0.5s, 0.2s, $easeInOutCubic);
			}
			&.menu-story {
				@include trans( transform, 0.5s, 0.3s, $easeInOutCubic);
			}
			&.menu-contact {
				@include trans( transform, 0.5s, 0.4s, $easeInOutCubic);
			}
		}
	}
}

.menu-open {
	position: absolute;
	right: -60px;
	// right: 0;
	top: 0;
	width: 60px;
	height: 60px;
	background-color: #1e1003;
	cursor: pointer;
	@include trans( transform, 0.3s, 0s, $easeInOutCubic);
	i {
		display: block;
		position: absolute;
		left: 20px;
		top: 28px;
		width: 20px;
		height: 2px;
		background-color: #fff;
		&:before{
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			left: 0;
			top: -8px;
			@include trans( transform, 0.3s, 0s, $easeInOutCubic);
		}
		&:after{
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			left: 0;
			top: 8px;
			@include trans( transform, 0.3s, 0s, $easeInOutCubic);

		}
	}
	&.show {
		transform : translate3d(-60px,0,0);
	}
	&:hover {
		i {

			&:before{
				transform : translate3d(0,-2px,0);
			}
			&:after{
				transform : translate3d(0,2px,0);
			}
		}
	}
	@include media(sp){
		right: -45px;
		width: 45px;
		height: 45px;
		i {
			left: 15px;
			top: 22px;
			width: 15px;
			height: 1px;
			&:before{
				width: 15px;
				height: 1px;
				left: 0;
				top: -5px;
			}
			&:after{
				width: 15px;
				height: 1px;
				left: 0;
				top: 5px;
			}
		}
		&.show {
			transform : translate3d(-45px,0,0);
		}
	}


}

.menu-close {
	position: absolute;
	right: -60px;
	top: 450px;
	width: 60px;
	height: 60px;
	background-color: #1e1003;
	cursor: pointer;
	@include trans( transform, 0.3s, 0s, $easeInOutCubic);

	i {
		position: absolute;
		left: 0;
		right: 0;
		display: block;
		&:before{
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			left: 22px;
			top: 29px;
			transform: rotate(45deg);			
		}
		&:after{
			content: '';
			display: block;
			width: 20px;
			height: 2px;
			background-color: #fff;
			position: absolute;
			left: 22px;
			top: 29px;
			transform: rotate(-45deg);			
		}
	}
	&.show {
		transform : translate3d(-60px,0,0);
	}
	&:hover {
		i {

			// &:before{
			// 	transform : translate3d(0,-2px,0);
			// }
			// &:after{
			// 	transform : translate3d(0,2px,0);
			// }
		}
	}
	@include media(sp){
		right: -45px;
		top: 337px;
		width: 45px;
		height: 45px;
		i {
			&:before{
				width: 15px;
				height: 1px;
				left: 16px;
				top: 22px;
			}
			&:after{
				width: 15px;
				height: 1px;
				left: 16px;
				top: 22px;
			}
		}
		&.show {
			transform : translate3d(-45px,0,0);
		}
	}
}

.white-screen {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(#fff,0.75);
	display: none;
	opacity: 0;
	@include trans( opacity, 0.5s, 0s, $easeInOutCubic);
	&.show {
		opacity: 1;
	}
}

.lang {
	display: none;
	position: absolute;
	right: 62px;
	top: 35px;
	width: 100px;
	li {
		position: relative;
		float: left;
		margin-right: 20px;
		@include font(14,14,100);
		font-weight: bold;
		font-family: $font-en;
		font-style: italic;
		&.current {
			
			&:after {
				content: '';
				position: absolute;
				bottom: -8px;
				left: 0;
				right: 0.1em;
				height: 2px;
				background-color: #1e1003;
			}

		}
	}
}
