@charset "utf-8";
///////////////////////////////////////////
// color.scss
///////////////////////////////////////////

// バックグランド
$body-background: #fff;

// 基本テキストカラー
$color-default : #1e1003 ;

$color-main : #1e1003 ;

// リンクカラー
$color-link : #1e1003 ;

// ホバーリンクカラー
$color-link-hover : #1e1003 ;

// 訪問済みリンクカラー
$color-link-visited : #1e1003 ;

// セレクションカラー
$selection-color: #ddd;

