@charset "utf-8";
///////////////////////////////////////////
// generic.scss
///////////////////////////////////////////

html {
	font-size: 62.5%; /*10px*/
}

body {
	// @include font($base-font-size,$base-line-height);
	font-family: $font-jp;
	color: $color-default;
	background: $body-background;
	width: 100%;
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// min-width: 1000px;
	// max-width: 100%;
}
input {
	font-family: $font-jp;	
}

a {
	text-decoration: none;
  color: $color-link;
  // text-decoration: underline;
}
.underline {
	cursor: pointer;
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}

a:visited{
  color: $color-link-visited;
}

a:hover{
  color: $color-link-hover;
  
}

// -----------------------------------------
// selection
// -----------------------------------------

::selection{
  background: $selection-color;
  // color:#ffffff;
}
::-moz-selection{
  background: $selection-color;
  // color:#ffffff;
}
