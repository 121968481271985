@charset "utf-8";

// Top

#top-main {
	height: 100%;
	position: relative;
	overflow: hidden;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border: 0 solid #fff;
		@include trans( border, 0.5s, 1.5s, $easeInOutCubic);
	}
	h1 {
		position: absolute;
		max-width: 516px;
		width: 68.5%;
	  top: 50%;
	  left: 50%;
	  transform: translate3d(-50%,-50%,0);
	}
	#bg-top-main {
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-image: url(/assets/images/top/pic-main.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		transform: scale(1.1);
		$selector: transform,opacity;
		@include trans( $selector, 1s, 0s, $easeInOutCubic);
	}
	&.show {
		&:after {
			border: 15px solid #fff;
			@include media(sp){
				border: 12px solid #fff;
			}
		}
		#bg-top-main {
			transform: scale(1);
			opacity: 1;
		}
		#top-main-arrow {
			transform: translate3d(0,0,0);
			opacity: 1;
		}
	}
}

.ico-arrow-top{
	background-image: url(/assets/images/top/ico-arrow-top.png);
	background-repeat: no-repeat;
	background-size: 100%;
	width: 30px;
	height: 16px;
	@include media(sp){
		width: 23px;
		height: 12px;
	}
}

#top-main-arrow {
	@extend .ico-arrow-top;
	$selector: transform,opacity;
	@include trans( $selector, 1s, 3s, $easeInOutCubic);
	transform: translate3d(0,10px,0);
	opacity: 0;
	position: absolute;
	left: 50%;
	margin-left: -15px;
	bottom: 57px;
	@include media(sp){
		margin-left: -12px;
		bottom: 40px;
	}
	&:before{
		content: '';
		@extend .ico-arrow-top;
		position: absolute;
		top: -10px;
		left: 0;
		@include media(sp){
			top: -8px;		
		}
	}
	&:after{
		content: '';
		@extend .ico-arrow-top;
		position: absolute;
		top: 10px;
		left: 0;
		@include media(sp){
			top: 8px;			
		}
	}
	
}