@charset "utf-8";

// Bg

.bg-title {
	opacity: 0;
	transform: scale(1.05);
	$selector: transform,opacity;
	&.show{
		@include trans( $selector, 1.5s, 0.5s, $easeInOutCubic);
		opacity: 1;
		transform: scale(1);
	}
	&.show2{
		opacity: 1;
		transform: scale(1);
	}
}

.box-bg {
	position: relative;
	overflow: hidden;
	min-height: 420px;
	@include media(sp){
		min-height: 315px;
	}
}

.bg {
	position: absolute;
	z-index: -1;
	left: 0;
	right: 0;
	top: -21%;
	height: 142%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	// &.bg-title {
	// 	top: -21%;
	// }
}

// 共有

.bg-common-contact {
	background-image: url(/assets/images/common/bg-contact.jpg);
}

.bg-common-instgagram {
	background-image: url(/assets/images/common/bg-instagram.jpg);
}

// トップページ

.bg-top-flow {
	background-image: url(/assets/images/top/bg-flow.jpg);
}

// about

.bg-about {
	background-image: url(/assets/images/about/bg-about.jpg);
}

.bg-about-1 {
	background-image: url(/assets/images/about/bg-about-1.jpg);	
}
.bg-about-2 {
	background-image: url(/assets/images/about/bg-about-2.jpg);	
}
.bg-about-3 {
	background-image: url(/assets/images/about/bg-about-3.jpg);	
}

// flow

.bg-flow {
	background-image: url(/assets/images/flow/bg-flow.jpg);
}

.bg-flow-1 {
	background-image: url(/assets/images/flow/bg-flow-1.jpg);	
}

// history

.bg-history {
	background-image: url(/assets/images/history/bg-history.jpg);
}

.bg-history-1 {
	background-image: url(/assets/images/history/bg-history-1.jpg);	
}
.bg-history-2 {
	background-image: url(/assets/images/history/bg-history-2.jpg);	
}
.bg-history-3 {
	background-image: url(/assets/images/history/bg-history-3.jpg);	
}
.bg-history-4 {
	background-image: url(/assets/images/history/bg-history-4.jpg);	
}

// contact

.bg-contact {
	background-image: url(/assets/images/contact/bg-contact.jpg);
}

.bg-contact-1 {
	background-image: url(/assets/images/contact/bg-contact-1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

